import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

function UsoMarca() {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Uso de Marca",
        }}
      />
      <main className="container h-1/2">
        <section className="mt-5 w-10/12 space-y-5">
          <h6 className="text-lg text-exoticberry">
            Declaración de Marca Comercial
          </h6>
          <p>Boone’s es una marcas registradas de E. & J. Gallo Winery.</p>
          <h6 className="text-lg text-exoticberry">
            Declaración Marcas Derechos de Autor:
          </h6>
          <p>
            Copyright © 2016 E & J Gallo Winery. Todos los derechos reservados.
          </p>
        </section>
      </main>
    </Layout>
  );
}

export default UsoMarca;
